<template>
  <div class="form-percentage-input input-group"
       :class="{ narrow: !fullWidth }">
    <b-form-input v-model="percentageValue"
                  type="text"
                  class="form-control"
                  autocomplete="off"
                  :autofocus="autofocus"
                  :placeholder="placeholder"
                  :state="isValid"
                  @focus="$emit('focus')"
                  @blur="$emit('blur')"
                  @keyup.enter="$emit('blur')"></b-form-input>
    <div class="input-group-append">
      <span class="input-group-text">%</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'percentageInput',
  props: {
    value: {},
    validationCallback: Function,
    useWholePercent: { // use 50 instead of .50
      type: Boolean,
      default: false
    },
    allowNull: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: 'Percentage'
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      percentageValue: ''
    }
  },
  mounted () {
    if (this.allowNull && this.value === null || this.value === undefined) {
      this.percentageValue = this.value

      // return
    }
  },
  watch: {
    percentageValue (newValue) {
      if (this.allowNull && (newValue === '' || newValue === null || newValue === undefined)) {
        this.$emit('input', newValue)

        return
      }

      this.$emit('input', this.useWholePercent ? newValue : (newValue / 100))
    },
    value: {
      handler () {
        if (this.allowNull && (this.value === null || this.value === undefined)) {
          return
        }

        // This is some hacky JavaScript to get around the fact that Javascript can't really do math very well.
        // EXAMPLE: in the JS console try multiplying 0.29 * 100, then try multiplying 0.29 * 1000 / 10
        this.$setCompat(this, 'percentageValue', this.useWholePercent ? this.value : this.value * 1000 / 10)
      },
      immediate: true
    }
  },
  computed: {
    isValid () {
      if (this.validationCallback) {
        return this.validationCallback(this.percentageValue)
      } else {
        return null
      }
    }
  }
}
</script>

<style scoped>
  .narrow {
    width: 9em;
  }
</style>
